import React from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Container } from "reactstrap";

const Sales = () => {

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Sales | Dashonic - React Admin & Dashboard Template</title>
                </MetaTags>

                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Dashboards" breadcrumbItem="Sales" />

                </Container>
            </div>
        </React.Fragment>
    );
}

export default Sales;