import MetaTags from "react-meta-tags";
import React from "react";

import { Row, Col, Alert, Container } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";


// actions
import { loginUser, socialLogin } from "../../slices/thunks";

//import images
import logoDark from "../../assets/images/logo-light-aweka.png";
import logoLight from "../../assets/images/logo-light-aweka.png";

interface LoginProps {
  history: any;
}

const Login = ({ history }: LoginProps) => {
  const dispatch = useDispatch();

  const { error } = useSelector((state: any) => ({
    error: state.login.error,
  }));

  // handleValidSubmit
  const handleValidSubmit = (event: any, values: any) => {
    dispatch(loginUser(values, history));
    console.log(dispatch(loginUser(values, history)));
    
  };

  const signIn = (res: any, type: any) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, history, type));
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        token: res.accessToken,
      };
      dispatch(socialLogin(postData, history, type));
    }
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Aweka Systeem</title>
      </MetaTags>
      <div className="authentication-bg min-vh-100">
        <div className="bg-overlay bg-white"></div>
        <Container>
          <div className="d-flex flex-column min-vh-100 px-3 pt-4">
            <Row className="justify-content-center my-auto">
              <Col md={8} lg={6} xl={4}>

                <div className="py-5">
                  <div className="mb-4 mb-md-5 text-center">
                    <Link to="/sales" className="d-block auth-logo">
                      <img src={logoDark} alt="" height="100" className="auth-logo-dark" />
                      <img src={logoLight} alt="" height="100" className="auth-logo-light" />
                    </Link>
                  </div>
                  <div className="text-center mb-4">
                    <h5>Welcome Back !</h5>
                    <p>AWEKA Energy Solutions</p>
                  </div>
                  <AvForm
                    onValidSubmit={(e: any, v: any) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    {error ? <Alert color="danger">{error}</Alert> : null}
                    <div className="form-floating form-floating-custom mb-3">
                      <AvField
                        name="email"
                        label="Email"
                        className="form-control"
                        placeholder="Enter email"
                        type="email"
                        required
                      />
                    </div>
                    <div className="form-floating form-floating-custom mb-3">
                      <AvField
                        name="password"
                        label="password"
                        type="password"
                        className="form-control"
                        required
                        placeholder="Enter Password"
                      />
                    </div>
                    <div className="form-check form-check-info font-size-16">
                      <input className="form-check-input" type="checkbox" id="remember-check" />
                      <label className="form-check-label font-size-14" htmlFor="remember-check">
                        Onthoud mij 
                      </label>
                    </div>

                    <div className="mt-3">
                      <button className="btn btn-info w-100" type="submit">Aanmelden</button>
                    </div>

                    <div className="mt-4 text-center"><Link className="text-muted" to="/forgot-password"><i className="mdi mdi-lock me-1"></i> Wachtwoord vergeten?</Link></div>

                  </AvForm>

                  <div className="mt-5 text-center text-muted">

                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl="12">
                <div className="text-center text-muted p-4">
                  <p className="mb-0">&copy;{new Date().getFullYear()} AWEKA Energy Solutions.</p>
                </div>
              </Col>
            </Row>

          </div>
        </Container>
      </div></React.Fragment>
  );
};

export default withRouter(Login);